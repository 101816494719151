import {
    cssVal,
    getDataByCodePostal, getDataBySiren,
    hidden, isEmpty, isUndef,
    newInput, texteForCivilite,
    texteForNatureActe,
    tryToDate,
    visible
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const CessionDroitBail = {
    title: "Cession de droit au bail",
    texte: "{{acteGroup}} enregistré au SDE de {{enregistrementSDEGroup.villeEnregistrement}}, le {{enregistrementSDEGroup.dateEnregistrement}},  dossier [[{{enregistrementSDEGroup.dossier}}]] référence [[{{enregistrementSDEGroup.reference}}]], {{cedantGroup}}, a cédé à {{acquereurGroup}}, moyennant le prix principal de {{cessionDroitBailGroup.montant}} €. La date d'entrée en jouissance est fixée au {{cessionDroitBailGroup.dateEntree}}. {{oppositionsGroup}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "cessionDroitBailGroup.dateEntree":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            case "acteGroup":
                tmpText = texteForNatureActe(varText, 'acteGroup');
                break;
            case "cedantGroup":
                if (!isUndef(varText['cedantGroup.typePersonne']) && varText['cedantGroup.typePersonne'] === 'morale') {
                    if (isEmpty(varText["cedantGroup.societeRcs"]) && isEmpty(varText["cedantGroup.societeDenomination"]) && isEmpty(varText["cedantGroup.societeRcsVille"]))
                        tmpText = '';

                    tmpText = ` ${cssVal(varText["cedantGroup.societeDenomination"])} ${isEmpty(varText["cedantGroup.sigle"]) ? '' : '(' + cssVal(varText["cedantGroup.sigle"]) + ')'} <span class='user-value'>${varText["cedantGroup.societeFormeJuridique"] || ''}</span> Capital : ${cssVal(varText["cedantGroup.societeCapital"])} €, <span class='user-value'>${varText["cedantGroup.societeAdresse"] || ''} ${varText["cedantGroup.societeAdresse2"] || ``} ${varText["cedantGroup.societeCodePostal"] || ''} ${varText["cedantGroup.societeVille"] || ''}</span>, Rcs <span class='user-value'>${varText["cedantGroup.societeRcsVille"] || ''} N°${varText["cedantGroup.societeRcs"] || ''}</span>`
                } else if (isEmpty(varText["cedantGroup.civilite"]) && isEmpty(varText["cedantGroup.nom"]) && isEmpty(varText["cedantGroup.prenom"])) {
                    tmpText = '';
                } else {
                    tmpText = ` <span class='user-value'><strong>${texteForCivilite(varText["cedantGroup.civilite"])} ${varText["cedantGroup.prenom"] || ''} ${varText["cedantGroup.nom"] || ''}</strong>, ${varText["cedantGroup.societeAdresse"] || ''} ${varText["cedantGroup.societeAdresse2"] || ``} ${varText["cedantGroup.societeCodePostal"] || ''} ${varText["cedantGroup.societeVille"] || ''} </span>`;
                }
                break;
            case "enregistrementSDEGroup.dateEnregistrement":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            case "acquereurGroup":
                switch (varText['acquereurGroup.statusAcquereur']) {
                    case "Personne physique déjà immatriculée au RCS":
                        tmpText = ` <strong>${texteForCivilite(varText['acquereurGroup.civilite'])} ${cssVal(varText['acquereurGroup.prenom'])} ${cssVal(varText['acquereurGroup.nom'])}</strong> ${cssVal(varText['acquereurGroup.adresse'])}${!isEmpty(varText['acquereurGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['acquereurGroup.codePostal'])} ${cssVal(varText['acquereurGroup.ville'])}, Rcs ${cssVal(varText['acquereurGroup.lieuImmatriculation'])} n°${cssVal(varText['acquereurGroup.rcs'])} le droit au bail portant sur des locaux sis ${cssVal(varText['cessionDroitBailGroup.adresse'])}${!isEmpty(varText['cessionDroitBailGroup.adresseSuite']) ? `, ${cssVal(varText['cessionDroitBailGroup.adresseSuite'])}` : ''}, ${cssVal(varText['cessionDroitBailGroup.codePostal'])}, ${cssVal(varText['cessionDroitBailGroup.ville'])}` ;
                        break;
                    case "Société en cours d'immatriculation":
                        tmpText = ` la sociéte <strong>${cssVal(varText['acquereurGroup.denomination'])}</strong>, ${cssVal(varText['acquereurGroup.formesSocietes'])} au capital de ${cssVal(varText['acquereurGroup.montantCapital'])} €, siège : ${cssVal(varText['acquereurGroup.adresse'])}${!isEmpty(varText['acquereurGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['acquereurGroup.codePostal'])} ${cssVal(varText['acquereurGroup.ville'])}, en cours d’immatriculation le droit au bail portant sur des locaux sis ${cssVal(varText['cessionDroitBailGroup.adresse'])}${!isEmpty(varText['cessionDroitBailGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['cessionDroitBailGroup.codePostal'])}, ${cssVal(varText['cessionDroitBailGroup.ville'])}`;
                        break;
                    case "Société déjà immatriculée":
                        tmpText = ` la sociéte <strong>${cssVal(varText['acquereurGroup.denomination'])}</strong>, ${cssVal(varText['acquereurGroup.formesSocietes'])} au capital de ${cssVal(varText['acquereurGroup.montantCapital'])} €, siège : ${cssVal(varText['acquereurGroup.adresse'])}${!isEmpty(varText['acquereurGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['acquereurGroup.codePostal'])} ${cssVal(varText['acquereurGroup.ville'])}, Rcs ${cssVal(varText['acquereurGroup.lieuImmatriculation'])} n°${cssVal(varText['acquereurGroup.rcs'])}, le droit au bail portant sur des locaux sis ${cssVal(varText['cessionDroitBailGroup.adresse'])} ${isEmpty(varText['acquereurGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['cessionDroitBailGroup.codePostal'])}, ${cssVal(varText['cessionDroitBailGroup.ville'])}`;
                        break;
                    default:
                        tmpText = ` <strong>${texteForCivilite(varText['acquereurGroup.civilite'])} ${cssVal(varText['acquereurGroup.prenom'])} ${cssVal(varText['acquereurGroup.nom'])}</strong> ${cssVal(varText['acquereurGroup.adresse'])}${!isEmpty(varText['acquereurGroup.adresseSuite']) ? `, ${cssVal(varText['acquereurGroup.adresseSuite'])}` : ''}, ${cssVal(varText['acquereurGroup.codePostal'])} ${cssVal(varText['acquereurGroup.ville'])}`;
                        break;
                }
                break;
            case "oppositionsGroup":
                if(varText['oppositionsGroup.typeOpposition'] === 'autre')  {
                    tmpText = cssVal(varText['oppositionsGroup.autre']);
                } else {
                    tmpText = cssVal(varText['oppositionsGroup.typeOpposition']);
                }
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        cedantGroup: [{}],
        acquereurGroup: [{}],
        cessionDroitBailGroup: [{}],
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            CessionDroitBail.schema[0].children[3] = val === "acte authentique" ? visible(CessionDroitBail.schema[0].children[3]) : hidden(CessionDroitBail.schema[0].children[3]);
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "enregistrementSDEGroup",
            label: "Enregistrement au SDE",
            type: "group",
            value: [{}],
            children: [
                newInput(def.dateEnregistrement, '_required'),
                newInput(def.villeEnregistrement, '_required'),
                newInput(def.dossier),
                newInput(def.reference),
            ]
        },
        {
            //2
            name: "cedantGroup",
            label: "Cédant",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            CessionDroitBail.schema[2].children[1] = val === "physique" ? visible(CessionDroitBail.schema[2].children[1]) : hidden(CessionDroitBail.schema[2].children[1]);
                            CessionDroitBail.schema[2].children[2] = val === "physique" ? visible(CessionDroitBail.schema[2].children[2]) : hidden(CessionDroitBail.schema[2].children[2]);
                            CessionDroitBail.schema[2].children[3] = val === "physique" ? visible(CessionDroitBail.schema[2].children[3]) : hidden(CessionDroitBail.schema[2].children[3]);

                            CessionDroitBail.schema[2].children[4] = val === "morale" ? visible(CessionDroitBail.schema[2].children[4]) : hidden(CessionDroitBail.schema[2].children[4]);
                            CessionDroitBail.schema[2].children[5] = val === "morale" ? visible(CessionDroitBail.schema[2].children[5]) : hidden(CessionDroitBail.schema[2].children[5]);
                            CessionDroitBail.schema[2].children[6] = val === "morale" ? visible(CessionDroitBail.schema[2].children[6]) : hidden(CessionDroitBail.schema[2].children[6]);
                            CessionDroitBail.schema[2].children[7] = val === "morale" ? visible(CessionDroitBail.schema[2].children[7]) : hidden(CessionDroitBail.schema[2].children[7]);
                            CessionDroitBail.schema[2].children[8] = val === "morale" ? visible(CessionDroitBail.schema[2].children[8]) : hidden(CessionDroitBail.schema[2].children[8]);

                        }
                    }
                }),
                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            CessionDroitBail.model.cedantGroup[0] = Object.assign(CessionDroitBail.model.cedantGroup[0], data.values.societe, data.values.siegeSocial);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            CessionDroitBail.schema[2].children[12].options = dataCP.options;
                            CessionDroitBail.model.cedantGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'hidden'),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}, 'hidden'),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}, 'hidden'),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}, 'hidden'),

                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            CessionDroitBail.schema[2].children[12].options = data.options;
                            CessionDroitBail.model.cedantGroup[0].societeVille = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //3
            name: "acquereurGroup",
            label: "Acquéreur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.statusAcquereur, '_required', {
                    on: {
                        input: (val) => {
                            CessionDroitBail.schema[3].children[1] = val === '1' || val === '4' ? visible(CessionDroitBail.schema[3].children[1]) : hidden(CessionDroitBail.schema[3].children[1]);
                            CessionDroitBail.schema[3].children[2] = val === '1' || val === '4' ? visible(CessionDroitBail.schema[3].children[2]) : hidden(CessionDroitBail.schema[3].children[2]);

                            CessionDroitBail.schema[3].children[3] = val === '3' || val === '4' ? visible(CessionDroitBail.schema[3].children[3]) : hidden(CessionDroitBail.schema[3].children[3]);
                            CessionDroitBail.schema[3].children[4] = val === '3' || val === '4' ? visible(CessionDroitBail.schema[3].children[4]) : hidden(CessionDroitBail.schema[3].children[4]);
                            CessionDroitBail.schema[3].children[5] = val === '3' || val === '4' ? visible(CessionDroitBail.schema[3].children[5]) : hidden(CessionDroitBail.schema[3].children[5]);

                            CessionDroitBail.schema[3].children[6] = val === '3' || val === '4' ? hidden(CessionDroitBail.schema[3].children[6]) : visible(CessionDroitBail.schema[3].children[6]);
                            CessionDroitBail.schema[3].children[7] = val === '3' || val === '4' ? hidden(CessionDroitBail.schema[3].children[7]) : visible(CessionDroitBail.schema[3].children[7]);
                            CessionDroitBail.schema[3].children[8] = val === '3' || val === '4' ? hidden(CessionDroitBail.schema[3].children[8]) : visible(CessionDroitBail.schema[3].children[8]);
                        }
                    }
                }),

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            CessionDroitBail.model.acquereurGroup[0] = Object.assign(CessionDroitBail.model.acquereurGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            CessionDroitBail.schema[3].children[12].options = dataCP.options;
                            CessionDroitBail.model.acquereurGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),

                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            CessionDroitBail.schema[3].children[12].options = data.options;
                            CessionDroitBail.model.acquereurGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_required'),
            ]
        },
        {
            //4
            name: "cessionDroitBailGroup",
            label: "Fonds vendu",
            type: "group",
            value: [{}],
            children: [
                newInput(def.montant, '_required'),
                newInput(def.dateEntree, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            CessionDroitBail.schema[4].children[6].options = data.options;
                            CessionDroitBail.model.cessionDroitBailGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_required'),
            ]
        },
        {
            //5
            name: "oppositionsGroup",
            label: "Oppositions",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeOpposition, '_required', {
                    on: {
                        input: (val) => {
                            CessionDroitBail.schema[5].children[1] = val === 'autre' ? visible(CessionDroitBail.schema[5].children[1]) : hidden(CessionDroitBail.schema[5].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden'),
            ]
        }
    ]
}